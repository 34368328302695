<template>
  <v-row class="mt-0 pt-0 px-5" justify="center">
    <v-col cols="12" class="py-0">
      <v-layout justify-center>
        <h2 class="color-text-main pt-4">LISTA DE COMISIONES</h2>
      </v-layout>
    </v-col>
    <v-col cols="12">
      <v-btn-toggle v-model="actionCommission" color="#E95233" group>
        <v-btn outlined value="active"> COMISIONES ACTUALES </v-btn>
        <v-btn outlined value="history"> HISTORIAL DE COMISIONES </v-btn>
      </v-btn-toggle>
    </v-col>
    <v-col class="pt-0 pb-0" :cols="colSearch">
      <v-text-field
        class="pt-5"
        v-model="search"
        prepend-inner-icon="mdi-magnify"
        clearable
        label="Buscar"
        placeholder="Ingrese aquí lo que desea buscar"
        color="#034f79"
        outlined
        dense
      ></v-text-field>
    </v-col>
    <v-col v-if="show.btn" class="pt-0 pb-0" cols="3">
      <v-layout justify-end>
        <v-btn
          :to="{
            name: 'commissionDetailsManager',
            params: { commissionId: commissionId[0].commission_id },
          }"
          block
          class="mt-5"
          color="#034f79"
          dark
        >
          DETALLES
        </v-btn>
      </v-layout>
    </v-col>
    <v-col class="py-0" cols="12">
      <v-divider class="my-2"></v-divider>
      <v-data-table
        v-model="commissionId"
        return-object
        :headers="headers"
        :items="items"
        single-select
        item-key="commissions.name"
        show-select
        :items-per-page.sync="itemsPerPage"
        :page.sync="page"
        :loading="loading.table"
        :search="search"
        loading-text="Cargando..."
        hide-default-footer
      >
        <!-- Sin resultados de busqueda -->
        <template v-slot:no-results>
          <v-alert icon="mdi-information-outline" prominent text type="info">
            <b>No se encontraron resultados con: {{ search }}</b>
          </v-alert>
        </template>
        <!-- Sin Data -->
        <template v-slot:no-data>
          <v-alert icon="mdi-information-outline" prominent text type="info">
            <b>No se encontró información.</b>
          </v-alert>
        </template>
        <!-- Loading -->
        <template v-slot:progress>
          <v-progress-linear
            color="#214484"
            :height="5"
            indeterminate
            stream
          ></v-progress-linear>
        </template>
        <!-- Status -->
        <template v-slot:item.statusVal="{ item }">
          <v-chip label dark small :color="getColorStatus(item.commissions.status_id)">
            {{ item.commissions.status.name }}
          </v-chip>
        </template>
      </v-data-table>
      <v-divider class="my-2"></v-divider>
    </v-col>
    <v-col class="mt-2" cols="6">
      <v-layout justify-start>
        <span class="grey--text pr-4 pt-2"># de items por página</span>
        <v-select
          class="select-page"
          style="width: 80px"
          dense
          v-model="itemsPerPage"
          :items="itemsPerPageArray"
          label="# de items por página"
          solo
          color="#034f79"
        ></v-select>
      </v-layout>
    </v-col>
    <v-col class="mt-2" cols="6">
      <v-layout justify-end>
        <span class="mr-4 grey--text pt-1">
          Página {{ page }} de {{ numberOfPages }}
        </span>
        <v-btn
          x-small
          fab
          dark
          color="#E95233"
          class="mr-1"
          @click="formerPage"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn x-small fab dark color="#E95233" class="ml-1" @click="nextPage">
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
      </v-layout>
    </v-col>
  </v-row>
</template>
<script>
import alert from "@/mixins/alert";
import color from "@/mixins/color";
import Api from "@/utils/api";
export default {
  mixins: [alert, color],
  data() {
    return {
      actionCommission: "active",
      commissionId: [],
      colSearch: 12,
      show: {
        btn: false,
      },
      loading: {
        table: false,
      },
      itemsPerPageArray: [5, 10, 20, 50],
      search: "",
      page: 1,
      itemsPerPage: 5,
      headers: [
        {
          text: "Comisión",
          align: "start",
          sortable: false,
          value: "commissions.name",
        },
        {
          text: "Centro de costo",
          align: "start",
          sortable: false,
          value: "commissions.cost_center.name",
        },
        {
          text: "Campaña",
          align: "start",
          sortable: false,
          value: "commissions.campaign.name",
        },
        {
          text: "Cliente",
          align: "start",
          sortable: false,
          value: "commissions.client.name",
        },
        {
          text: "Estado",
          align: "start",
          sortable: false,
          value: "statusVal",
        },
      ],
      items: [],
    };
  },
  watch: {
    "actionCommission": function (newA) {
      this.items = [];
      if (newA == "active") {
        this.getCommission();
      }
      if (newA == "history") {
        this.getHistoryCompleted();
      }
    },
    commissionId: function (newId) {
      if (newId.length > 0) {
        this.show.btn = true;
        this.colSearch = 9;
      } else {
        this.show.btn = false;
        this.colSearch = 12;
      }
    },
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.items.length / this.itemsPerPage);
    },
  },
  methods: {
    getColorStatus(status) {
      var color = this.color(status);
      return color;
    },
    getCommission() {
      this.loading.table = true;
      Api.Manager()
        .getManager()
        .then((res) => {
          if (res.data.cod == 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              if(res.data.data[i].commissions != null){
                this.items.push(res.data.data[i])
              }
            }
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    getHistoryCompleted() {
      this.loading.table = true;
      Api.Manager()
        .getHistoryManager()
        .then((res) => {
          if (res.data.cod == 0) {
                   for (let i = 0; i < res.data.data.length; i++) {
              if(res.data.data[i].commissions != null){
                this.items.push(res.data.data[i])
              }
            }
            this.alert(res.data.message, "Muy bien", "success");
          } else {
            this.alert(res.data.message, "Oops...", "error");
          }
        })
        .catch((error) => {
          this.alert(error.response.data.message, "Oops...", "error");
        })
        .finally(() => (this.loading.table = false));
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
  },
  mounted() {
    this.getCommission();
  },
};
</script>
<style>
.select-page .v-input__slot {
  width: 80px !important;
  box-shadow: none !important;
  border: 1px solid;
}
</style>
